<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="25" size="40" ref="toppane">
			<!--<div style="max-height: 100%" class="overflow-y-auto">-->
			<div>
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems"
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
				<template v-slot:top>
					<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px" ref="tabletopbar">
						<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
							<v-btn>全部待读</v-btn>
							<v-btn>今日待读</v-btn>
							<v-btn>明日待读</v-btn>
							<v-btn>解读完成</v-btn>
							<v-btn>放弃解读</v-btn>
						</v-btn-toggle>
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate1" label="解读完成日期起" readonly v-bind="attrs"
									v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
							</template>
							<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate2" label="解读完成日期止" readonly v-bind="attrs"
									v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
							</template>
							<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto" class="ml-4"
							append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
					</div>
					<v-divider/>
				</template>
				<template v-slot:item.nointerpr="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" :color="!!item.giveupInterpretation ? 'orange' : '#aaa'"
								small
								@click.stop="showGiveupInterpretation(item)">
								phone_disabled
							</v-icon>
						</template>
						放弃解读
					</v-tooltip>
				</template>
				<template v-slot:item.apntPthlDate="{ item }">
					{{formatTime(item.apntPthlDate)}}
				</template>
				<template v-slot:item.progress.operative="{ item }">
					{{formatTime(item.progress.operative)}}
				</template>
				<template v-slot:item.pathologyReturnEst="{ item }">
					{{formatTime(item.pathologyReturnEst)}}
				</template>
				<template v-slot:item.progress.pathologyReturn="{ item }">
					{{formatTime(item.progress.pathologyReturn)}}
				</template>
				<template v-slot:item.progress.pathology="{ item }">
					{{formatTime(item.progress.pathology)}}
				</template>
				<template v-slot:item.idcard="{ item }">
					{{getMaskedIdcard(item)}}
				</template>
				<template v-slot:item.gender="{ item }">
					{{getGender(item)}}
				</template>
				<template v-slot:item.age="{ item }">
					{{getAge(item)}}
				</template>
				<template v-slot:item.opts="{ item }">
					{{getOptsStr(item)}}
				</template>
				</v-data-table>
	        </div>
			</pane>
			<pane min-size="30" size="60" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto pl-4">
					<v-tabs>
						<v-tab :disabled="!canEdit">报告</v-tab>
						<v-tab-item class="pt-4" :disabled="!canEdit">
							<v-btn outlined rounded large color="primary" width="200" @click.stop="iu=true" :disabled="!canEdit">上传病理报告</v-btn>
							<v-btn rounded large color="primary" width="200" class="ml-4" @click.stop="dlgRtn=true"
								:loading="saving1" :disabled="saving1||locked1||!canEdit">病理报告返回确认</v-btn>
							<v-btn rounded large color="primary" width="200" class="ml-4" @click.stop="saveAddition"
								:loading="saving1" :disabled="saving1||locked4||!canEdit">追加项目报告返回确认</v-btn>
							<v-container fluid v-if="rpts.length > 0" class="mt-1">
								<v-divider/>
								<v-row class="mt-2">
									<v-card v-for="(r,idx) in rpts" :key="idx" class="ma-2" max-width="300">
										<v-btn v-if="r.canDelete && canEdit" fab x-small absolute right @click.stop="remove(r.valueOf())"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
										<v-img v-if="r.isPdf" @click.stop="showPdf(idx)" height="300">
											<object :data="r.valueOf()+'#toolbar=0&navpanes=0&scrollbar=0'" type="application/pdf" width="100%" height="100%" style="pointer-events:none;"/>
										</v-img>
										<v-img v-else :src="r.valueOf()" lazy-src="../assets/logo.png" width="300" height="300" @click.stop="showImage(idx)">
											<template v-slot:placeholder>
												<v-row class="fill-height ma-0" align="center" justify="center">
													<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
												</v-row>
											</template>
										</v-img>
									</v-card>
								</v-row>
							</v-container>
						</v-tab-item>
						<v-tab :disabled="!canEdit">专家建议</v-tab>
						<v-tab-item class="pt-4 pb-4" :disabled="!canEdit">
							<v-textarea outlined hide-details auto-grow class="mb-2" style="width:500px" rows="10" label="专家建议" v-model.trim="expertAdvice"/>
							<v-btn rounded large color="primary" width="180" class="ml-4" @click.stop="saveExpertAdvice"
								:loading="saving2" :disabled="saving2">保存</v-btn>
						</v-tab-item>
						<v-tab :disabled="!isDoctor">病历记录</v-tab>
						<v-tab-item :disabled="!isDoctor">
							<div>
								<v-btn class="ml-8 mt-6 mb-4" rounded width="120" color="primary" @click.stop="addRecord">添加病历</v-btn>
								<diagnose-history :items="MedicalHistory" @edit="editRecord" @remove="removeRecord"/>
							</div>
						</v-tab-item>
						<v-tab :disabled="!isDoctor">复诊单</v-tab>
						<v-tab-item :disabled="!isDoctor">
							<div>
								<v-btn rounded color="primary" width="200" class="mt-0 mb-4" @click.stop="genSS">编辑复诊单</v-btn>
							</div>
							<div v-if="selected[0] && selected[0].subsequent" style="width:500px;">
								<v-text-field filled readonly label="诊断" :value="selected[0].subsequent.according"/>
								<v-row>
									<v-col><v-text-field filled readonly label="胃镜复诊周期" :value="selected[0].subsequent.periodw"/></v-col>
									<v-col><v-text-field filled readonly label="肠镜复诊周期" :value="selected[0].subsequent.periodc"/></v-col>
								</v-row>
								<v-text-field filled readonly label="治疗建议" :value="selected[0].subsequent.tx"/>
								<v-text-field filled readonly label="检查建议" :value="selected[0].subsequent.exam"/>
							</div>
						</v-tab-item>
						<v-tab>解读</v-tab>
						<v-tab-item class="pt-4 pb-4">
							<v-textarea outlined hide-details class="mb-2" style="width:600px" rows="10" label="解读建议" v-model="advice"/>
							<div class="mt-2" style="margin-left:320px;">
								<v-btn large color="primary" width="120" class="ml-4" @click.stop="pasteFromMr"
									:disabled="saving2||locked3">从病历粘贴</v-btn>
								<v-btn large color="primary" width="120" class="ml-4" @click.stop="save2"
									:loading="saving2" :disabled="saving2||locked2">解读完成确认</v-btn>
							</div>
						</v-tab-item>
					</v-tabs>
	            </div>
			</pane>
		</splitpanes>
		<image-uploader v-model="iu" :clouddir="clouddir" @saved="onPicSaved" allowPdf/>
		<viewer v-if="rpts.length > 0" @inited="inited" ref="viewer" :images="rpts.filter(x=>!x.isPdf)" style="display:none">
			<template slot-scope="scope">
				<img v-for="src in scope.images" :src="src.valueOf()" :key="src.valueOf()">
			</template>
		</viewer>
		<diagnose v-model="diagnose" :patient="selected[0]" :mrindex="mrindex" @change="fetchMr"/>
		<v-dialog persistent width="300" v-model="dlgRtn">
			<v-card>
				<v-card-title>病理报告返回确认</v-card-title>
				<v-card-text>
					<v-form>
						<v-text-field label="追加项目-胃" placeholder="无" persistent-placeholder v-model.trim="addition_w"/>
						<v-text-field label="追加项目-肠" placeholder="无" persistent-placeholder v-model.trim="addition_c"/>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :loading="saving1" :disabled="saving1||locked1||!canEdit" @click.stop="save1">确认</v-btn>
					<v-btn color="primary" outlined @click.stop="dlgRtn=false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dlgPdf" width="60%">
			<v-card style="height:90vh">
				<object v-if="!!curpdf" :data="curpdf+'#navpanes=0'" type="application/pdf" width="100%" style="height:calc(100% - 6px)"></object>
			</v-card>
		</v-dialog>
		<v-dialog v-model="intpn" width="500">
			<v-card>
				<v-card-title>放弃解读</v-card-title>
				<v-card-text>
					<v-form ref="intpnForm">
						<v-text-field label="放弃解读原因" v-model.trim="intpnReason" clearable/>
					</v-form>
				</v-card-text>
				<v-divider/>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :loading="loading" :disabled="loading" @click.stop="doGiveupInterpretation">确认</v-btn>
					<v-btn outlined :loading="loading" @click.stop="intpn=false">返回</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<subsequent v-model="subsequentDlg" :item="selected[0]" :readonly="!isDoctor"/>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, formatDate, calculateAge, today, tomorrow} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import store from '../store.js'
	import ImageUploader from '@/components/ImageUploader.vue'
	import DiagnoseHistory from '../components/DiagnoseHistory.vue';
	import Diagnose from '../components/Diagnose.vue';
	import Subsequent from '../components/Subsequent.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'', value:'nointerpr', width:24, sortable: false, cellClass:'bcicon'},
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'预约解读时间', value:'apntPthlDate', width:180},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'手术医生', value:'endoscopyResult.doctorT', width:100},
                    {text:'活检', value:'endoscopyResult.biopsy', width:120},
                    {text:'息肉', value:'endoscopyResult.polyp', width:120},
                    {text:'病理报告返回预计', value:'pathologyReturnEst', width:180},
                    {text:'病理报告返回时间', value:'progress.pathologyReturn', width:180},
                    {text:'解读完成时间', value:'progress.pathology', width:180},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'性别', value:'gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'身高', value:'QA.baseinfo.bodyheight', width:80},
                    {text:'体重', value:'QA.baseinfo.bodyweight', width:80},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'诊所', value:'region', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				saving1: false,
				saving2: false,
				locked1: false,
				locked2: false,
				locked3: false,
				locked4: false,
				filter: 0,
				options: {sortBy:['apntPthlDate'], sortDesc:[false]},
				totalItems: 0,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				pname: '',
				advice: '',
				diagnose: false,
				tableheight: undefined,
				mrindex: -1,
				iu: false,
				isDoctor: false,
				canEdit: false,
				rpts: [],
				MedicalHistory: [],
				dlgRtn: false,
				addition_w: '',
				addition_c: '',
				expertAdvice: '',
				dlgPdf: false,
				curpdf: '',
				subsequentDlg: false,
				intpn: false,
				intpnId: null,
				intpnReason: '',
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege(['病理报告返回','病理报告解读','健康顾问','复诊开单']);
			if (!this.authed) return;
			this.isDoctor = this.$hasPrivilege(['病理报告解读','复诊开单']);
			this.canEdit = this.$hasPrivilege(['病理报告返回','病理报告解读']);
			this.formatTime = formatTime;
			this.pdate1 = formatDate(Date.now());
			this.pdate2 = formatDate(Date.now());
			this.$watch(()=>store.currentRegion, this.fetchData);
			this.fetchData();
        },
		computed: {
			clouddir() {
				if (this.selected.length === 0) return "";
				return `PathologyReports/${this.selected[0]._id}`;
			}
		},
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async fetchMr() {
				this.MedicalHistory = [];
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp2order')
						.where({
							pid: this.selected[0].pid,
							MedicalHistory: _.exists(true),
						})
						.field({MedicalHistory:true})
						.get();
					const r = res.data.find(x => x._id === this.selected[0]._id);
					if (r) {
						this.selected[0].MedicalHistory = r.MedicalHistory;
					}
					this.MedicalHistory = res.data
						.flatMap(x => {
							if (x._id === this.selected[0]._id) {
								x.MedicalHistory.forEach((y, index) => {
									y.id = x._id;
									y.mrindex = index;
								});
								//便于复诊单从病历粘贴
								this.selected[0].MedicalHistory = x.MedicalHistory;
							}
							return x.MedicalHistory;
						})
						.sort((a, b) => a.time - b.time);
				} catch(err) {
					console.error(err);
				}
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filters = [
					{'endoscopyResult.pathology':'有', 'progress.pathology':_.exists(false), 'giveupInterpretation':_.exists(false)},
					{'progress.pathologyReturn':_.exists(true), apntPthlDate:_.gt(today.begin()).lt(today.end()), 'progress.pathology':_.exists(false)},
					{'progress.pathologyReturn':_.exists(true), apntPthlDate:_.gt(tomorrow.begin()).lt(tomorrow.end()), 'progress.pathology':_.exists(false)},
					{'progress.pathology':_.exists(true)},
					{'giveupInterpretation':_.exists(true)},
				];
				let filter = this.pname ? [{'pii.username':this.pname}] : [];
				if (this.filter === 3) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 && t2) {
						filter.push({'progress.pathology':_.gt(t1).lt(t2)});
					} else if (this.pdate1) {
						filter.push({'progress.pathology':_.gt(t1)});
					} else if (this.pdate2) {
						filter.push({'progress.pathology':_.lt(t2)});
					}
				}
				filter.push({hide:_.neq(true)});
//				filter.push({region:store.currentRegion});
				if (this.$hasPrivilege(['病理报告返回','病理报告解读'])) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId: u.consultantId});
				}
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			inited(viewer) {
				this.$viewer = viewer;
			},
			showImage(idx) {
//				if (this.selected.length === 0) return;
//				if (this.selected[0].pathologyReports === 0) return;
				this.$viewer.show();
				this.$viewer.view(idx);
			},
			showPdf(idx) {
				this.curpdf = this.rpts[idx].valueOf();
				this.dlgPdf = true;
			},
			getMaskedIdcard(item) {
//				return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
				return item.pii.idcard;
			},
            getGender(item) {
                return item.pii.gender;
            },
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			showGiveupInterpretation(item) {
				this.intpnId = item._id;
				this.intpnReason = item.giveupInterpretation?.reason;
				this.intpn = true;
			},
			async doGiveupInterpretation() {
				if (!this.$refs.intpnForm.validate()) return;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'giveupInterpretation',
							data: {
								id: this.intpnId,
								reason: this.intpnReason || null,
							}
						}
					});
					this.intpn = false;
					await this.fetchData();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
			async save1() {
				/*
				const res = await this.$dialog.prompt({
					title:'病理报告返回确认',
					text:'追加项目',
					persistent: true,
					textField: {placeholder:'无追加项目时不需要填写'},
					actions: {false:'取消', true:{text:'确定',color:'primary'}}
				})
				if (res === false) return;
				*/
                this.saving1 = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'pathologyReturn',
						data:{
							id,
							additionW: this.addition_w||undefined,
							additionC: this.addition_c||undefined,
						}
					}});
					await this.fetchData();
	                this.locked1 = true;
					this.dlgRtn = false;
					this.$dialog.message.success('确认完成');
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('确认失败');
				}
				this.saving1 = false;
			},
			async saveExpertAdvice() {
				if (!this.expertAdvice) {
					this.$dialog.message.warning('请先填写内容再保存');
					return;
				}
                this.saving2 = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'saveExpertAdvice', data:{id, expertAdvice:this.expertAdvice}}});
					this.selected[0].expertAdvice = this.expertAdvice;
					this.$dialog.message.success('保存完成');
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.saving2 = false;
			},
			pasteFromMr() {
				if (this.MedicalHistory && this.MedicalHistory.length > 0) {
					const mrs = this.MedicalHistory[this.MedicalHistory.length-1].mrs;
					if (mrs && mrs['处置']) {
						this.advice = mrs['处置'];
						this.selected[0].pathologyTips = this.advice;
						return;
					}
				}
				this.$dialog.message.error('没有符合条件的记录');
			},
			async save2() {
                this.saving2 = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'pathologyInterpreted', data:{id, pathologyTips:this.advice}}});
					await this.fetchData();
	                this.locked2 = true;
				} catch(err) {
					console.error(err);				
				}
				this.saving2 = false;
			},
			async saveAddition() {
				const res = await this.$dialog.confirm({
					text: '确定追加项目报告已返回?',
					title: '确认提示',
				});
				if (!res) return;
                this.saving1 = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'pathologyAdditionReturn',
						data:{
							id,
						}
					}});
					await this.fetchData();
	                this.locked4 = true;
					this.$dialog.message.success('确认完成');
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('确认失败');
				}
				this.saving1 = false;
			},
			async onPicSaved(files) {
				if (this.selected.length === 0) return;
				const id = this.selected[0]._id;
				await this.$tcbapp.callFunction({name:"wp2mp",
					data:{
						funcname:'pathologyUpload',
						data: {
							id,
							files
						}
					}
				});
				const db = this.$tcbapp.database();
				const res = await db.collection('wp2order').doc(id).field({pathologyReports:true}).get();
				if (res.data.length > 0) {
					this.selected[0].pathologyReports = res.data[0].pathologyReports;
					await this.fetchRpts();
				}
			},
			addRecord() {
				this.mrindex = -1;
				this.diagnose = true;
			},
			editRecord(idx) {
				this.mrindex = idx;
				this.diagnose = true;
			},
			async removeRecord(index) {
				const res = await this.$dialog.warning({
					text: '确定要删除此项记录？',
					title: '提示'
				});
				if (!res) return;
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'removeMedicalRecord',
							data: {
								id,
								index,
							}
						}
					});
					if (!res.result.ret) {
						this.$dialog.error({title:'删除失败', text:res.result.msg});
						return;
					}
					this.fetchMr();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
					this.loading = false;
				}
			},
			async fetchRpts() {
				this.rpts = [];
				const s = this.selected[0];
				const {username, dob} = s.pii;
				const res = await this.$getEdReports({username, dob});
				const s1 = res.map(x => new String(x.imagesrc));
				const s2 = s.pathologyReports ? s.pathologyReports.map(x => new String(x)) : [];
				s2.forEach(x => {
					x.canDelete = true;
					const str = x.valueOf();
					x.isPdf = str.substring(str.length - 3).toLowerCase() === 'pdf';
				});
				this.rpts = s2.concat(s1);
			},
			async remove(cloudpath) {
				const res = await this.$dialog.warning({
					text: '确定要删除此图片？',
					title: '提示'
				});
				if (!res) return;
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'pathologyRemove',
							data: {
								id,
								cloudpath,
							}
						}
					});
					const index = this.selected[0].pathologyReports.indexOf(cloudpath);
					if (index > -1) {
						this.selected[0].pathologyReports.splice(index, 1);
					}
					await this.fetchRpts();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
					this.loading = false;
				}
			},
			genSS() {
				this.subsequentDlg = true;
			}
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.fetchData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.fetchData();
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.locked1 = !!newitem[0].progress.pathologyReturn || !this.$hasPrivilege('病理报告返回');
				//需求49。没预约解读的也可以填解读建议。
				this.locked2 = !!newitem[0].progress.pathology || !this.$hasPrivilege('病理报告解读');
				this.locked3 = !newitem[0].progress.pathologyReturn || !this.$hasPrivilege('病理报告解读');
				this.locked4 = !!newitem[0].progress.pathologyAdditionReturn || (!newitem[0].pathologyAdditionW && !newitem[0].pathologyAdditionC) || !this.$hasPrivilege('病理报告返回');
				this.advice = newitem[0].pathologyTips;
				this.expertAdvice = newitem[0].expertAdvice;
				this.addition_w = newitem[0].pathologyAdditionW || '';
				this.addition_c = newitem[0].pathologyAdditionC || '';
				this.fetchRpts();
				this.fetchMr();
			}
		},
        components: { Splitpanes, Pane, ImageUploader, DiagnoseHistory, Diagnose, Subsequent }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
.bcicon {
	padding: 0!important;
}
</style>